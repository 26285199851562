<template>
  <v-card color="grey lighten-4" light>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Order History</v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text class="pa-0" >
        <v-container fluid>
          <v-data-iterator
            :items="history"
            :options.sync="pagination"
            :loading="loading"
            loading-text="Looking up your orders..."
          >
            <template v-slot:item="{item}">
              <v-row dense>
                <v-col cols="12">
                  <v-card @click.native="gotToDetails(item.orderId)" style="cursor: pointer;">
                    <v-card-text class="pa-0">
                      <v-container fluid class="py-0">
                        <v-row dense align="center">
                          <v-col cols="11">
                            <v-row dense>
                              <v-col cols="6">
                                <h3>{{item.organization.name}}</h3>
                                <div class="caption">Order {{item.orderId}}</div>
                              </v-col>
                              <v-col class="text-right" cols="6" >
                                <h3>{{item.total | usDollars}}</h3>
                                <div class="caption">{{item.status}}</div>
                              </v-col>
                            </v-row>
                            <v-row dense v-for="(lineItem, i) in item.items" :key="i">
                              <v-col cols="12" class="pl-9">
                                <v-icon color="color1" class="mr-4">fas fa-clipboard-check</v-icon>
                                {{lineItem.description}}
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col class="text-right" cols="1" >
                            <v-btn icon text class="ma-0" color="color3">
                              <v-icon>fas fa-chevron-right</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                      <div
                        class="caption grey--text text--lighten-2 text-end mr-2"
                        v-if="user.vbl"
                      >{{item.processor}}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-card-text>
  </v-card>

</template>

<script>
import * as mutations from '../../store/MutationTypes'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      history: [],
      loading: false,
      pagination: {}
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    getHistory () {
      this.loading = true
      this.$VBL.user.getOrderHistory(this.$route.query.uid)
        .then(response => {
          this.history = response.data
          this.loading = false
        })
        .catch(error => {
          console.log(error.response)
          this.loading = false
        })
    },
    gotToDetails (orderId) {
      this.$store.commit(mutations.LOADING, 'OrderHistory')
      this.$VBL.cart.getBag({
        id: orderId,
        email: null
      })
        .then(response => {
          this.$store.commit(mutations.SET_ORDER, response.data)
          this.$router.push({ name: 'order-details', params: { orderId: orderId } })
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'OrderHistory')
        })
    }
  },
  created () {
    this.getHistory()
  }
}
</script>

<style>

</style>
